


































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import firebase from "@/plugins/firebase";
import "@/types";
import Confirm from "@/components/Confirm.vue";
import AddRemoveCategory from "./AddRemoveCategory.vue";

export default Vue.extend({
  components: {
    Confirm,
    AddRemoveCategory
  },

  metaInfo() {
    return {
      title: "Eğitimleri Yönet"
    };
  },

  data() {
    return {
      search: "",
      loading: false,
      headers: [
        {
          text: "Ad",
          align: "start",
          filterable: true,
          value: "name"
        },
        { text: "Eğitmen", value: "instructor" },
        { text: "Ücret", value: "price" },
        { text: "Durum", value: "disabled", width: "90px" },
        { text: "Tarih", value: "startDate", width: "200px" },
        { text: "", value: "actions", sortable: false, width: "50px" }
      ],
      items: [] as Course[],
      selectedItem: {},
      dialog: false,
      dialogContent: ""
    };
  },

  methods: {
    async getItems() {
      this.loading = true;

      try {
        // Get all courses
        const query = await firebase
          .firestore()
          .collection("/apps/kahev-akademi/courses")
          .orderBy("name")
          .get();

        this.items = [];
        query.forEach(doc => {
          if (doc.exists) {
            const course = {
              id: doc.id,
              name: doc.data().name,
              code: doc.data().code,
              urlCode: doc.data().urlCode,
              startDate: doc.data().startDate.toDate(),
              finishDate: doc.data().finishDate
                ? doc.data().finishDate.toDate()
                : null,
              desc: doc.data().desc,
              disabled: doc.data().disabled,
              img: doc.data().img,
              instructor: doc.data().instructor,
              price: doc.data().price
            } as Course;

            this.items.push(course);
          }
        });
      } catch (err) {
        this.$notify({
          type: "error",
          text: `Eğitim listesi alınamadı.`
        });
        throw new Error(`Eğitim bilgileri alınamadı. ${err.message}`);
      } finally {
        this.loading = false;
      }
    },

    async deleteCourse(course: Course) {
      const $confirm = this.$refs.confirm as InstanceType<typeof Confirm>;
      const confirmation = await $confirm.open(
        "Emin misiniz?",
        "Bu işlem geri alınamaz. Eğitimi silmek istediğinizden emin misiniz?",
        {
          color: "red"
        }
      );

      if (!confirmation) return;

      try {
        // Eğitimi sil
        await firebase
          .firestore()
          .doc(`/apps/kahev-akademi/courses/${course.id}`)
          .delete();

        // Ön izleme resmini sil
        if (course.img) {
          const imgRef = firebase.storage().refFromURL(course.img);
          await imgRef.delete();
        }

        this.$notify({
          type: "success",
          title: "İşlem Tamam",
          text: "Eğitim başarıyla silindi."
        });

        await this.getItems();
      } catch (err) {
        this.$notify({
          type: "eror",
          title: "Hata!",
          text: "Eğitim silinemedi."
        });

        throw new Error(`Eğitim silinemedi. ${err.message}`);
      }
    },

    async addRemoveCategory(item: Course) {
      this.selectedItem = {
        id: item.id,
        name: item.name
      };

      // Open dialog
      this.dialogContent = "AddRemoveCategory";
      this.dialog = true;
    }
  },

  async mounted() {
    await this.getItems();
  }
});
